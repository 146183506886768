/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useContext, useEffect, useState } from 'react';
import Button from '../components/atoms/Button/Button';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import AuthContext from '../context/AuthProvider';
import { getStorage } from '../helpers/general';
import { useForm } from '@mantine/form';
import * as styles from './changepassword.module.scss';
import { useToast } from '@chakra-ui/react';

const ChangePasswordPage = () => {
  const auth = useContext(AuthContext);
  const toast = useToast();
  const handleChangePassword = auth && auth.changePassword;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [fields, setFields] = useState({});
  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validate: {
      password: value =>
        value.length < 7 ? 'Password must be at least 7 characters long' : null,
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null
    }
  });

  const [init, setInit] = useState({});
  const [token, setToken] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (isLoggedIn && typeof window !== 'undefined') {
      window.location = '/account';
    }

    if (typeof window !== 'undefined' && window.location.search !== '') {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('c')) {
        setCustomerId(searchParams.get('c'));
      }
      if (searchParams.has('t')) {
        setToken(searchParams.get('t'));
      }
    }

    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [isLoggedIn, userEmail, fields, init]);

  const attemptChangePassword = e => {
    e.preventDefault();
    const validate = form.validate();

    if (validate.hasErrors) {
      toast({
        title: 'Validation error',
        description:
          validate.errors.password || validate.errors.confirmPassword,
        status: 'error',
        position: 'top-right',
        colorScheme: 'secondary'
      });
      return;
    }

    handleChangePassword(
      {
        authentication: { new_password: form.values.password }
      },
      customerId,
      token
    );
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout>
        <Seo title="Change Password" />
        <Container size="large" className={styles.root}>
          <div className="form-container">
            <h3>Change Password</h3>
            <p>
              Passsword must be at least 7 characters and contain both
              alphabetic and numeric characters
            </p>
            <form onSubmit={e => attemptChangePassword(e)}>
              <div>
                <div className={`formField`}>
                  <label htmlFor="password">New Password</label>
                  <input
                    type="password"
                    name="password"
                    tabIndex="4"
                    {...form.getInputProps('password')}
                  />
                </div>

                <div className={`formField`}>
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type="password"
                    name="password_confirm"
                    tabIndex="4"
                    {...form.getInputProps('confirmPassword')}
                  />
                </div>
              </div>

              <div className="formField">
                <Button level="tertiary" type="buttonSubmit">
                  Change Password
                </Button>
              </div>
            </form>
          </div>
        </Container>
      </Layout>
    );
  } else return null;
};

export default ChangePasswordPage;
